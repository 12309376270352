.carousel-item {
    height: 500px;
  }
  

.carousel-item img {
    height: 100%; 
    object-fit: cover;
}
/* #myCarousel {
    height: 500px;
  }
  
  .carousel-item img {
    height: 100%;
    object-fit: contain;
  } */
  